import React, { useState, useRef, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import { MDBModalHeader } from 'mdb-react-ui-kit'
import RankCss from '../../Components/RankCss'
function Card({ data }) {
    console.log("data" + JSON.stringify(data))

    // const [studentData, setStudentData] = useState({})
    const [optSmModal, setOptSmModal] = useState(true);
    const toggleShow = () => setOptSmModal(!optSmModal);
    // useEffect(() => {
    //    setStudentData(data)
    //   }, [])
    // console.log(studentData)
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    return (
        <div className='p-4 paymentPage' ref={componentRef}>
            {data && <RankCss>
                <div style={{ border: '2px solid gray', width: '720px', margin: 'auto' }} >
                    <center className='flex justify-between items-center mb-4 mt-4 pl-2 pr-2' width='720'>
                        <div>
                            <img style={{ width: '3.8rem' }} src='https://aicee.in/static/media/logo1.bdff491eff12e960f90c.png' alt='aicee' />
                        </div>
                        <div>
                            <h4 className='font-black text-[#e35d26]'>All India Combined Entrance Examination 2025</h4>
                            <h5 className='font-bold text-[#e35d26] ' style={{ textDecoration: 'underline', marginTop: '0.4rem' }}>Score & Rank Card</h5>
                        </div>
                        <div style={{ paddingRight: '1.5rem' }}>
                            {/* <img style={{width:'5rem'}} src='https://aicee.in/static/media/logo1.32bf711ad21b08f4f19d.png' alt='aicee'/> */}
                        </div>
                    </center>
                    <table border='1' width='718' cellPadding='6' className='mt-4'>
                        <tr>
                            <td width='200' className='heading font-bold'>
                                Application No
                            </td>
                            <td colSpan='3'>
                                {data.application_no}
                            </td>
                        </tr>
                        <tr>
                            <td className='heading font-bold'>Student Name</td>
                            <td style={{ textTransform: 'capitalize' }}>{data.user.name}</td>
                            <td rowSpan='4' width='200' style={{ margin: 'auto' }}>
                                <img style={{ width: '8rem', margin: 'auto' }} src={data.docs.photo} alt='img' />
                            </td>
                        </tr>
                        <tr>
                            <td className='heading font-bold'>Father's Name</td>
                            <td style={{ textTransform: 'capitalize' }}>{data.personal_details.fatherName}</td>

                        </tr>
                        <tr>
                            {/* <td className='heading font-bold'>Mother's Name</td>
                            <td>{data.motherName}</td> */}
                        </tr>
                        <tr>
                            <td className='heading font-bold'>Mode Of Examination</td>
                            <td>{data.moe}</td>
                        </tr>
                        <tr>
                            <td className='heading font-bold'>Program Opted</td>
                            <td>{data.prog_opted === "engg" ? "Engineering" : data.prog_opted}</td>
                            <div cellPadding='none'>
                                <img style={{ width: '13rem', height: '2.3rem', padding: '-1rem', overflow: 'hidden' }} src={data.docs.signature} alt='signature' />
                            </div>
                        </tr>
                        <tr>
                            <td className='heading font-bold'>Level Of Examination</td>
                            <td colSpan='3'>{data.loe}</td>
                        </tr>
                    </table>
                    {
                        data.loe == "Other (12th Level)" || data.loe === "Graduation Level" ?
                            <table border='1' width='718' cellPadding='2'>
                                <tr>
                                        <td width='200' colSpan='6' className='heading' style={{ fontWeight: 'bolder', textAlign: 'center' }}>
                                            Performance Report
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width='200' colSpan='2' className='heading' style={{ fontWeight: 'bolder', textAlign: 'center' }}>
                                            Obtained Marks
                                        </td>
                                        <td width='200' colSpan='3' className='heading' style={{ fontWeight: 'bolder', textAlign: 'center' }}>
                                            Total Marks
                                        </td>
                                    </tr>
                                    <tr>

                                        <td colSpan='2' className='' style={{ textAlign: 'center' }}>
                                            {data.obtained_marks}
                                        </td>
                                        <td colSpan='3' style={{ textAlign: 'center' }}>
                                            {data.total_marks}
                                        </td>
                                    </tr>
                            </table>
                            :
                            <>
                                {/* <table border='1' width='718' cellPadding='2'>
                                    <tr>
                                        <td width='200' colSpan='6' className='heading' style={{ fontWeight: 'bolder', textAlign: 'center' }}>
                                            Performance Report
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width='200' colSpan='2' className='heading' style={{ fontWeight: 'bolder', textAlign: 'center' }}>
                                            Obtained Marks
                                        </td>
                                        <td width='200' colSpan='3' className='heading' style={{ fontWeight: 'bolder', textAlign: 'center' }}>
                                            Total Marks
                                        </td>
                                    </tr>
                                    <tr>

                                        <td colSpan='2' className='' style={{ textAlign: 'center' }}>
                                            {data.obtained_marks}
                                        </td>
                                        <td colSpan='3' style={{ textAlign: 'center' }}>
                                            {data.total_marks}
                                        </td>
                                    </tr>
                                    
                                </table> */}
                                <table border='1' width='718' cellPadding='6'>
                                <tr>
                                    <td width='200' colSpan='5' className='heading' style={{ fontWeight: 'bolder', textAlign: 'center' }}>
                                        Performance Report
                                    </td>

                                </tr>
                                <tr>
                                    <td style={{ backgroundColor: '#e0e0e0' }} className='text-center font-bold' width='150'>Physics</td>
                                    <td style={{ backgroundColor: '#e0e0e0' }} className='text-center font-bold' width='150'>Chemistry</td>
                                    <td style={{ backgroundColor: '#e0e0e0' }} className='text-center font-bold' width='150'>
                                        {data.loe == "PCM (12th Level)" ? "Math" : "Bio"}

                                    </td>
                                    <td style={{ backgroundColor: '#e0e0e0' }} className='text-center font-bold' width='150'>Current Affairs & Aptitude</td>
                                    <td style={{ backgroundColor: '#e0e0e0' }} className='text-center font-bold' width='150'>Total Marks</td>
                                </tr>
                                <tr>
                                    <td className='text-center' width='150'>{data.phy}</td>
                                    <td className='text-center' width='150'>{data.che}</td>
                                    <td className='text-center' width='150'>
                                        {data.bio && data.bio}
                                        {data.math && data.math}

                                    </td>
                                    <td className='text-center' width='150'>{data.gk}</td>
                                    {
                                        data.bio &&
                                        (<td className='text-center' width='150'>{Number(data.phy) + Number(data.che) + Number(data.gk) + Number(data.bio)} </td>)
                                    }
                                    {
                                        data.math &&
                                        (<td className='text-center' width='150'>{Number(data.phy) + Number(data.che) + Number(data.gk) + Number(data.math)} </td>)
                                    }

                                </tr>

                            </table>
                            </>
                    }
                    <table border='1' width='718' cellPadding='6'>
                        <tr>
                            <td width='200' colSpan='2' className='heading' style={{ fontWeight: 'bolder', textAlign: 'center' }}>
                                Rank
                            </td>
                        </tr>
                        <tr>
                            <td width='350' className=''>
                                AICEE - 2025 Rank
                            </td>
                            <td colSpan='3'>
                                {data.rank}
                            </td>
                        </tr>
                    </table>

                    <div>
                        <p style={{ fontSize: '12px', padding: '5px', textTransform: 'none' }}>
                            AICEE Scores are normalized scores across multi session papers and are based on the relative performance of all those who appeared for the examination in one session
                        </p>
                        <p style={{ fontSize: '12px', padding: '1px', textTransform: 'none' }}>
                            <span className='text-red-500'> Note :-</span> Registration for Counselling Process will begin from 16th Feb, 2025  please visit the official website regularly for more updates : https://aicee.in. For any query write us a mail on info@aicee.in or Call :- 7488661870
                        </p>
                        {/* <p style={{ fontSize: '12px', padding: '1px', textTransform: 'none' }}>
                            <span className='text-red-500'> Note :-</span> For Engineering admission in Maharashtra, West Bengal and Odisha Students needs to appear in Jee Mains alongside AICEE.
                        </p> */}
                    </div>
                </div>
            </RankCss>}
            <center><button onClick={handlePrint} style={{ backgroundColor: '#e35d26' }} className='pl-4 pr-4 pt-1 mt-5 text-white pb-1 rounded-md mt-2'><i class="fas fa-print"></i> &nbsp; Print / Download</button></center>
        </div>
    )
}

export default Card